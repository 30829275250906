<template>
  <div class="start-track-app">
    <h2>{{$t('application')}} <br> <span>INVOXIA GPS</span></h2>

    <p>{{$t('download_the_app')}}</p>

    <div>
      <a href="https://play.google.com/store/apps/details?id=com.invoxia.track" target="_blank"><img src="/images/google-play.png" alt=""></a>
      <a href="https://apps.apple.com/app/invoxia-gps/id1261314542" target="_blank"><img src="/images/app-store.png" alt=""></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StartTrackApp',
  data() {
    return {}
  },
  mounted: function () {
     window.zE('webWidget', 'hide');
  },
  created: function () {
  }
}
</script>

<style lang="scss" scoped>

.start-track-app {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5em;
  padding: 0 5%;
  background-image: url("/images/background-start-track-app.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    width: 100%;
    margin-top: 2em;
    font-weight: 800;
    font-size: 2em;

    span {
      font-size: 1.1em;
      line-height: 41px;
      color: transparent;
      -webkit-text-stroke: 1.5px;
      -webkit-text-stroke-color: #5B74F4;
    }
  }

  p {
    font-size: 20px;
    line-height: 150%;
  }

  div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    img {
      width: 150px;
    }
  }
}
</style>